@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "App";

@tailwind base;
@tailwind components;
@tailwind utilities;

body, .MuiFormLabel-root, .MuiMenuItem-root {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px !important;
}

code {
  font-family: Montserrat, sans-serif !important;
}

* {
  font-family: Montserrat, sans-serif !important;
}

html, body, #root {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
}

.no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}

::-webkit-scrollbar, ::-webkit-scrollbar-button {
  height: 0;
}


@media screen and (max-width: 375px) {
  .MuiStepper-alternativeLabel {
    overflow-x: scroll;
  }
}

.sidenav---navicon---3gCRo {
  vertical-align: center !important;

  svg {
    margin: auto;
  }
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo, .sidenav---sidenav-toggle---1KRjR {
  font-size: 20px !important;
  height: 40px !important;
  padding: 12px 0 0;
}

@media screen and (max-width: 720px) {

  //.sidenav---sidenav---_2tBP {
  //  min-width: 40px !important;
  //  width: 40px;
  //}

  .sidenav---sidenav---_2tBP:not(.sidenav---expanded---1KdUL) {
    min-width: 40px !important;
    width: 40px;
  }

  .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo, .sidenav---sidenav-toggle---1KRjR {
    width: 40px !important;
    font-size: 20px !important;
    height: 40px !important;
    margin-right: 2px !important;
  }

  .main-box.full-width {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .main-box:not(.full-width) {
    width: calc(100vw - 40px) !important;
    margin-left: 40px !important;
  }

  .main-sign {
    height: 100vh;
    width: 100%;
    border: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.main-box {
  width: calc(100% - 64px);
  margin-left: 64px;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 30%) !important;
}

.input-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap !important;

  input {
    transition: .3s;
    max-width: 50px;
    min-width: 0 !important;
    height: 60px;
    margin: 0 8px;
    border: 4px solid grey;
    border-radius: 4px;
    font-size: 1.5em;
    text-align: center;
    position: relative;

    &:focus {
      border-color: #303F9F;
    }
  }
}
